<script setup lang="ts">
import type { Database } from '~~/types/database'
const supabase = useSupabaseClient<Database>()

const searchModelOpen = ref(false)
const searchTerm = ref('')

// Create reactive search queries using useAsyncData
const { data: packageResults, status: packageStatus } = useAsyncData(
  'packages',
  async () => {
    if (!searchTerm.value) return []
    
    const { data, error } = await supabase
      .from('packages')
      .select('id, package, description')
      .or(`package.ilike.%${searchTerm.value}%,description.ilike.%${searchTerm.value}%`)
      .limit(5)
    
    if (error) {
      console.error(error)
      return []
    }

    return data.map(item => ({
      id: item.id,
      label: item.package,
      suffix: item.description,
      to: `/packages/${item.id}`,
      type: 'Package'
    }))
  },
  {
    watch: [searchTerm],
    default: () => []
  }
)

const { data: qtraxIdResults, status: qtraxIdStatus } = useAsyncData(
  'qtraxIds',
  async () => {
    if (!searchTerm.value) return []
    
    const { data, error } = await supabase
      .from('qtrax')
      .select('id, sf01, sf24, sf25, qtrax_links(package_id, link_type)')
      .ilike('sf01', `%${searchTerm.value}%`)
      .limit(5)
    
    if (error) {
      console.error(error)
      return []
    }

    return data
      .map(item => {
        const link = item.qtrax_links.find(link => link.link_type === 'IWP')
        if (!link) return null
        return {
          id: item.id,
          label: item.sf01,
          suffix: item.sf25 + ' - ' + item.sf24,
          type: 'Package',
          to: link.package_id ? `/packages/${link.package_id}` : null
        }
      })
      .filter(Boolean)
  },
  {
    watch: [searchTerm],
    default: () => []
  }
)

const { data: qtraxDrawingResults, status: qtraxDrawingStatus } = useAsyncData(
  'qtraxDrawings',
  async () => {
    
    if (!searchTerm.value) return []
    
    const { data, error } = await supabase
      .from('qtrax')
      .select('id, sf10, sf24, sf25, qtrax_links(package_id, link_type)')
      .ilike('sf10', `%${searchTerm.value}%`)
      .limit(5)
      console.log(data);
      
    
    if (error) {
      console.error(error)
      return []
    }

    return data
      .map(item => {
        const link = item.qtrax_links.find(link => link.link_type === 'IWP')
        if (!link) return null
        return {
          id: item.id,
          label: item.sf10,
          suffix: item.sf25 + ' - ' + item.sf24,
          type: 'Package',
          to: link.package_id ? `/packages/${link.package_id}` : null
        }
      })
      .filter(Boolean)
  },
  {
    watch: [searchTerm],
    default: () => []
  }
)

// Combine all groups into the new format
const groups = computed(() => {
  console.log(qtraxIdResults.value)
  
  return [
  {
    id: 'qtraxIds',
    label: searchTerm.value ? `Qtrax Identifiers matching "${searchTerm.value}"...` : 'Qtrax Identifiers',
    items: qtraxIdResults.value,
    ignoreFilter: true
  },
  {
    id: 'packages',
    label: searchTerm.value ? `Packages matching "${searchTerm.value}"...` : 'Packages',
    items: packageResults.value,
    // We'll handle filtering in the query, so we don't want the CommandPalette to filter
    ignoreFilter: true
  },
  {
    id: 'qtraxDrawings',
    label: searchTerm.value ? `Qtrax Drawings matching "${searchTerm.value}"...` : 'Qtrax Drawings',
    items: qtraxDrawingResults.value,
    ignoreFilter: true
  }
]})

// Determine if any search is still loading
const isLoading = computed(() => 
  packageStatus.value === 'pending' || 
  qtraxIdStatus.value === 'pending' || 
  qtraxDrawingStatus.value === 'pending'
)

function onSelect(item: any) {
  if (item.to) {
    navigateTo(item.to)
  }
  searchModelOpen.value = false
}

defineShortcuts({
  meta_k: {
    usingInput: true,
    handler: () => {
      searchModelOpen.value = true
    }
  }
})
</script>

<template>
  <UButton 
    @click="searchModelOpen = !searchModelOpen" 
    block 
    variant="outline"
    color="neutral" 
    size="md"
    icon="i-heroicons-magnifying-glass" 
    class="w-80"
  >
    Search...
    <template #trailing>
      <div class="flex items-center gap-0.5 ml-4">
        <UKbd value="meta" />
        <UKbd value="K" />
      </div>
    </template>
  </UButton>

  <UModal v-model:open="searchModelOpen">
    <template #content>
      <UCommandPalette
        v-model:search-term="searchTerm"
        :loading="isLoading"
        :groups="groups"
        placeholder="Search for packages, tags, drawings, etc..."
        class="h-80"
        @update:model-value="onSelect"
      />
    </template>
  </UModal>
</template>